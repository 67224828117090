<template>
  <div>
    <client-only>
  <div v-if="banner">
    <div v-if="videoObject && videoObject.text.length > 0">
      <youtube-embed class="video-player" :url="videoObject.text" />
        <div class="content-center">
          <div class="content-constraint">
            <div v-if="isParallax">
              <image-banner :banner="banner" hero class="content-top" />
            </div>
            <div v-else>
            <div v-if="banner.banner_image.url && banner.banner_image.url.length > 0" class="banner-image-full">
              <base-image
                  :source="banner.banner_image.url | fullImagePath('S')"
                  :alt="'Baker Book House Banner Image'"
                />
              </div>
            </div>
            <!-- <youtube-embed v-if="videoObject && videoObject.text.length > 0" class="video-player" :url="videoObject.text" /> -->
            <slices-block v-if="slices" :slices="slices" />
          </div>
        </div>
    </div>
    <div v-else>
      <div v-if="isParallax">
        <image-banner :banner="banner" hero class="content-top" />
      </div>
      <div v-else>
        <div v-if="banner.banner_image.url && banner.banner_image.url.length > 0" class="banner-image-full">
          <div v-if="banner.image_clicked_url && banner.image_clicked_url.url.length > 0">
            <a :href="banner.image_clicked_url.url" :target="banner.image_clicked_url.target">
              <base-image
              :source="banner.banner_image.url | fullImagePath('S')"
              :alt="'Baker Book House Banner Image'"
              />
            </a>
          </div>
          <base-image
v-else
              :source="banner.banner_image.url | fullImagePath('S')"
              :alt="'Baker Book House Banner Image'"
            />
        </div>
      </div>
      <div v-if="banner && banner.use_image_carousel == true">
        <div v-if="banner.carousel_images && banner.carousel_images.length > 0">
              <carousel
              :images="banner.carousel_images"
              :delay="parseInt(banner.carousel_delay)"
/>
        </div>
      </div>
      <div class="content-center">
          <div class="content-constraint">
            <slices-block v-if="slices" :slices="slices" />
          </div>
        </div>
    </div>
  </div>
    </client-only>
  </div>
</template>

<script>
import prismicAPI from '@/helpers/prismic-api'
import ImageBanner from '@/components/slices/image-banner'
import SlicesBlock from '@/components/slices/slices-block'
import YoutubeEmbed from '@/components/multimedia/youtube-embed'
import Carousel from '~/components/multimedia/carousel.vue'
export default {
  name: 'Home',
  components: {
    ImageBanner,
    SlicesBlock,
    YoutubeEmbed,
    Carousel
  },
  data () {
    return {
      videoObject: {},
      isParallax: true,
      slices: null,
      banner: null
    }
  },
  head () {
    return {
      title: 'New, Used, and Bargain Christian Books',
      titleTemplate: 'Baker Book House - %s'
    }
  },
  async asyncData ({ error, req, $axios }) {
    try {
      const home = await prismicAPI.getSingle('home', req)
      if (!home || !home.document) {
        return {
          slices: null,
          banner: null,
          videoObject: null,
          isParallax: true
        }
      }
      const { body: slices, ...banner } = home.document
      for (const slice of slices.filter(x => x.slice_type === 'featured_items')) {
        const isbns = slice.items.map(x => x.isbn)
        const res = await $axios.get('store/products/by-isbns', {
          params: {
            isbns
          }
        })
        slice.items = res.data
      }
      let isParallaxImage = true
      if (home.document.use_parallax_effect !== null && !home.document.use_parallax_effect) {
        isParallaxImage = false
      }
      let embedUrl = {}
      if (home.document.video_url && home.document.video_url.length > 0) {
        embedUrl = home.document.video_url[0]
      }
      return {
        slices,
        banner,
        videoObject: embedUrl,
        isParallax: isParallaxImage
      }
    } catch (e) {
      error(e)
    }
  },
  mounted () {
    // Ensure the ScarabQueue library is loaded
    if (typeof ScarabQueue !== 'undefined') {
      // Push the 'go' command to the ScarabQueue
      // eslint-disable-next-line no-undef
      // ScarabQueue.push(['go'])
    } else {
      console.error('ScarabQueue is not defined')
    }
  }
}
</script>
<style scoped>
.content-top {
  margin-top:3em;
}
</style>
